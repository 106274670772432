<template>
  <div>
    <img src="../../assets/images/liucheng.png" class="liucheng" alt="">
    <div class="content">
      <div class="felx flex-center t-box">
        <img src="../../assets/images/zhuangshi.png" class="zhuangshi" alt="">
        <div class="title">贷款申请通道</div>
        <img src="../../assets/images/zhuangshi.png" class="zhuangshi" alt="">
      </div>
      <form>
        <div class="l-input">
          <input class="input-con" v-model="formData.name" type="text" placeholder="请输入您的姓名"/>
        </div>
        <div class="l-input felx flex-space">
          <input class="input-con" v-model="formData.applyMoney" type="number" placeholder="请输入申请贷款金额"/>
          <span>（万元）</span>
        </div>
        <div class="l-input">
          <input class="input-con" v-model="formData.phone" type="number"
                 oninput="if(value.length>11)value=value.slice(0,11)"
                 placeholder="请输入您的手机号码"/>
        </div>
        <div class="l-input felx flex-space">
          <input class="input-con" v-model="formData.code" type="number" placeholder="请输入您验证码"/>
          <div class="line"></div>
          <div :class="['code-text',{'active-code':isActive}]" @click="getCode">
            {{ isActive ? num + 's后重新获取' : '获取验证码' }}
          </div>
        </div>
        <div class="btn" @click="submit">立即申请</div>
      </form>
    </div>
    <!--申请条件-->
    <div class="sqtj">
      <div class="sqth-item">
        <div class="sqtj-title felx flex-a">
          <div class="sqtj-line"></div>
          <span class="title-text">申请条件</span>
        </div>
        <div class="sqtj-cotent">
          <div class="con-text">1、年龄18-65周岁</div>
          <div class="con-text">2、房屋在大重庆（主城或区县）</div>
          <div class="con-text">3、普通住宅、洋房、划拨地、占用房均可操作</div>
          <div class="con-text">4、房龄：2002年的房产</div>
        </div>

      </div>
      <div class="sqth-item">
        <div class="sqtj-title felx flex-a">
          <div class="sqtj-line"></div>
          <span class="title-text">准备资料</span>
        </div>
        <div class="sqtj-cotent">
          <div class="con-text">1、个人及配偶二代身份（有配偶则提供）</div>
          <div class="con-text">2、房产证</div>
          <div class="con-text">3、个人及配偶户口簿全页及婚姻证明（如离婚需 要离婚协议）</div>
          <div class="con-text">4、收入证明（工作证明或营业执照）</div>
          <div class="con-text">5、银行流水（近6个月银行流水）</div>
        </div>

      </div>
    </div>
    <!--    申请成功弹层-->
    <applicationSuccessful @close="isSucc=!isSucc"  v-if="isSucc"></applicationSuccessful>

  </div>
</template>

<script>
import {Toast} from "vant";
import applicationSuccessful from '../../components/application-successful';
export default {
  name: "apply-immediately",
  components: {applicationSuccessful},
  data() {
    return {
      formData: {
        productId: '',//产品id
        name: '',//申请人名
        applyMoney: '',//申请人名
        phone: '',//手机号码
        code: '',//验证码

      },
      isActive: false,//是否获取验证码
      num: 60,//倒计时
      isSucc:false,//是否申请成功
    }
  },
  mounted() {
    this.formData.productId = this.$route.query.id;
  },
  methods:{
    //提交
    submit() {
      this.$axios.post(`/business/mobile/applyLoanDTO`, this.formData).then(() => {
        this.isSucc=true;
      })
    },
    //倒计时
    countdown() {
      let timer = setInterval(() => {
        --this.num;
        if (this.num <= 0) {
          this.isActive = false;
          this.num = 60;
          clearInterval(timer);
        }
      }, 1000)
    },
    //获取验证嘛
    getCode() {
      if (!this.isActive) {
        if (this.formData.phone.length == 11) {
          this.$axios.get(`/business/mobile/getCode/${this.formData.phone}`).then(() => {
            this.isActive = true;
            this.countdown();
          })
        } else {
          Toast('填写完整手机号')
        }
      }

    },
  },

}
</script>

<style scoped>
.liucheng {
  width: 100%;
  height: 170px;
  margin-bottom: 30px;
}

.content {
  padding: 0 25px;
}

.t-box {
  margin-bottom: 25px;
}

.zhuangshi {
  width: 17px;
  height: 9px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #FB560A;
  margin: 0 17px;
}

.sqtj {
  margin-top: 50px;
}

.sqtj-title {
  margin-bottom: 20px;
}

.sqtj-line {
  width: 3px;
  height: 13px;
  background: #3F85FF;
  margin-right: 8px;
}

.title-text {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.sqtj-cotent {
  padding: 0 25px;
}

.con-text {
  font-size: 14px;
  color: #666666;
  line-height: 30px;
}

.sqth-item {
  padding: 0 15px;
  margin-bottom: 25px;
}

.active-code {
  color: #999999;
}
</style>